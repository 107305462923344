// extracted by mini-css-extract-plugin
export var aArrow1 = "Rb";
export var aArrow2 = "Sb";
export var aButton1 = "Bb";
export var aButton2 = "Cb";
export var aButton3 = "Db";
export var aButton4 = "Eb";
export var aButton5 = "Fb";
export var aButtonHeaderLogin = "Gb";
export var aButtonHeaderRegister = "Hb";
export var aButtonHeaderSpLogin = "Kb";
export var aButtonLogin = "Jb";
export var aButtonRegister = "Ib";
export var aEmpty = "wb";
export var aInner = "zb";
export var aMarquee = "Ab";
export var aTagAnnounce = "Ob";
export var aTagClose = "Nb";
export var aTagEntry = "Lb";
export var aTagFree = "Qb";
export var aTagLottery = "Pb";
export var aTagPayment = "Mb";
export var aText = "Tb";
export var aTitle = "xb";
export var animationMaquee = "Ub";
export var animationMarquee = "Vb";
export var pMarqueePageTitle = "yb";